import './Navigation.scss';

// show the user the information, which section is currently in their viewport
const sectionsList = document.querySelectorAll('.example');

const highlightViewportSectionLink = (section) => {
    // coordinates of the section
    const coord = section.getBoundingClientRect();

    // link to the section
    const relevantLink = document.querySelector(`[data-anchor][href='#${section.id}']`);

    if (relevantLink) {
        // active link
        const addActiveSessionInfo = () => {
            relevantLink.classList.add('navigation__link--active');
            relevantLink.focus();
        };

        // inactive link
        const removeActiveSessionInfo = () => {
            relevantLink.classList.remove('navigation__link--active');
            relevantLink.blur();
        };

        // check the coordinates
        if (coord.top < window.innerHeight / 2 && coord.top > 0) {
            addActiveSessionInfo();
        } else if (coord.top > window.innerHeight / 2) {
            removeActiveSessionInfo();
        } else if (coord.bottom > window.innerHeight / 2) {
            addActiveSessionInfo();
        } else if (coord.bottom < window.innerHeight / 2 && coord.bottom > 0) {
            removeActiveSessionInfo();
        }
    }
};

sectionsList.forEach((section) => {
    highlightViewportSectionLink(section);
    window.addEventListener('scroll', () => highlightViewportSectionLink(section));
});
